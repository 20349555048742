var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card card-primary card-outline"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('h4',[_c('i',{staticClass:"fa fa-briefcase"}),_vm._v(" Firmen")])]),_c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"mr-1 btn btn-light btn-sm",attrs:{"type":"button"},on:{"click":_vm.loadCompanies}},[_c('i',{staticClass:"fas fa-sync"})]),(_vm.$auth.check('companies.create'))?_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Neue Firma ")]):_vm._e()])]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.companies,"search-options":{ 
                              enabled: true
                          },"pagination-options":{
                              enabled: true,
                              mode: 'pages',
                              perPage: 20,
                              perPageDropdownEnabled: true,
                              nextLabel: 'Nächste',
                              prevLabel: 'Vorherige',
                              rowsPerPageLabel: 'Zeilen pro Seite',
                              ofLabel: 'von',
                              pageLabel: 'Seite',
                              allLabel: 'Alle',
                          },"styleClass":"vgt-table condensed striped"},on:{"on-row-dblclick":_vm.onRowDoubleClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[(_vm.$auth.check('companies.edit'))?_c('router-link',{staticClass:"mr-1 btn btn-warning btn-xs",attrs:{"to":{name: 'companies.details', params: {id: props.row.id}}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e()],1):_vm._e(),(props.column.field == 'online')?_c('span',[(props.row.online == 1)?_c('span',[_c('i',{staticClass:"ml-2 fas fa-check"})]):_vm._e(),(props.row.online == 0)?_c('span',[_c('i',{staticClass:"ml-2 fas fa-times"})]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('div',{staticClass:"card-footer"})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }