<template>
  <b-container fluid>
      <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Firmen</h4>
                        </div>
                        <div class="card-tools">
                            <button type="button" class="mr-1 btn btn-light btn-sm" @click="loadCompanies">
                                <i class="fas fa-sync"></i>
                            </button>
                            <button type="button" class="btn btn-primary btn-sm" v-if="$auth.check('companies.create')">
                                <i class="fas fa-plus"></i> Neue Firma
                            </button>
                        </div>
                    </div>

                    <div class="card-body" >
                        <vue-good-table 
                            :columns="columns"
                            :rows="companies"
                            :search-options="{ 
                                enabled: true
                            }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 20,
                                perPageDropdownEnabled: true,
                                nextLabel: 'Nächste',
                                prevLabel: 'Vorherige',
                                rowsPerPageLabel: 'Zeilen pro Seite',
                                ofLabel: 'von',
                                pageLabel: 'Seite',
                                allLabel: 'Alle',
                            }"
                            @on-row-dblclick="onRowDoubleClick" 
                            styleClass="vgt-table condensed striped">

                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'action'">
                                    <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'companies.details', params: {id: props.row.id}}" v-if="$auth.check('companies.edit')"><i class="fas fa-edit"></i></router-link>
                                    <!-- <b-button size="xs" @click="deleteArticle(props.row.id)" variant="danger" v-if="$auth.check('articles.destroy')"><i class="fas fa-trash"></i></b-button> -->
                                </span>  

                                <span v-if="props.column.field == 'online'">
                                    <span v-if="props.row.online == 1">
                                        <i class="ml-2 fas fa-check"></i>
                                    </span>
                                    <span v-if="props.row.online == 0">
                                        <i class="ml-2 fas fa-times"></i>
                                    </span>
                                </span>
                                <span v-else>
                                    {{props.formattedRow[props.column.field]}}
                                </span>     
                            </template> 
                        </vue-good-table>
                    </div>

                    <div class="card-footer">

                    </div>
                </div>
            </div>
        </div>
  </b-container>
</template>

<script>
export default {
    name: "CompaniesIndex",
    title: "Firmenübersicht",

    data(){
        return {
            companies: [],

            columns: [   
                {label: 'Name', field: 'name'},
                {label: 'Domain', field: 'domain'},
                {label: 'Theme', field: 'theme'},
                {label: 'Onlineshop', field: 'online'},
                {label: 'Aktionen', field: 'action', tdClass: 'text-center', width: '7%'},
            ],
        }
    },

    methods:{
        onRowDoubleClick(){},

        loadCompanies(){
            this.$Progress.start();
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created(){
        this.loadCompanies();
    }

}
</script>

<style>

</style>